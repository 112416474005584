import React from 'react';
import Chart from 'react-apexcharts';

const MyBarChart = ({ factor }) => {
  const name = factor.factor_name || factor.outcome_name;
  const latestScorePercentage = factor.latest_score * 10;
  const lastScorePercentage = factor.last_score * 10;

  const isScoreHigher = latestScorePercentage > lastScorePercentage;
  const latestScoreColor = isScoreHigher ? '#27CDA7' : '#F15062';

  // Corrected calculation: Overall difference
  const changeValue = latestScorePercentage - lastScorePercentage;
  const changeValueFormatted = `${changeValue.toFixed(1)}%`;

  // Calculate y-axis min and max to zoom in on the chart
  const minScore = Math.min(lastScorePercentage, latestScorePercentage);
  const maxScore = Math.max(lastScorePercentage, latestScorePercentage);

  const yAxisMin = Math.max(0, minScore - 5);
  const yAxisMax = Math.min(100, maxScore + 5);

  const series = [
    {
      data: [
        {
          x: 'Last Score',
          y: lastScorePercentage,
          fillColor: '#666666',
          strokeColor: '#888888',
          fillPattern: {
            style: 'verticalLines',
            width: 6,
            height: 6,
            strokeWidth: 3,
            strokeOpacity: 1,
            strokeColor: '#888888',
            backgroundColor: '#BBBBBB',
          },
        },
        {
          x: 'Latest Score',
          y: latestScorePercentage,
          fillColor: latestScoreColor,
          strokeColor: 'transparent',
        },
      ],
    },
  ];

  const options = {
    chart: {
      id: 'score-comparison',
      toolbar: {
        show: false,
      },
      animations: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 4,
        columnWidth: '56%', // Bars are 20% less wide
      },
    },
    xaxis: {
      labels: {
        style: {
          fontFamily: 'Raleway',
        },
      },
    },
    yaxis: {
      min: yAxisMin,
      max: yAxisMax,
      tickAmount: 5,
      labels: {
        formatter: function (val) {
          return Math.round(val);
        },
        style: {
          fontFamily: 'Raleway',
        },
      },
      title: {
        text: `${name} Score %`,
        style: {
          fontFamily: 'Raleway',
        },
      },
    },
    // Removed the top title as per your request
    // title: {
    //   text: `${name} Score Comparison`,
    //   align: 'center',
    //   style: {
    //     fontFamily: 'Raleway',
    //     fontWeight: 'bold',
    //   },
    // },
    tooltip: {
      enabled: false,
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    fill: {
      type: 'solid', // Default fill type
    },
    stroke: {
      show: true,
      width: 2,
      colors: [], // Allows per-data-point stroke colors
    },
    annotations: {
      yaxis: [
        {
          y: latestScorePercentage,
          borderColor: '#000000',
          strokeDashArray: 4,
          label: {
            borderColor: '#000000',
            style: {
              color: '#fff',
              background: '#000000',
              fontFamily: 'Raleway',
            },
            text: `Change: ${changeValueFormatted}`,
          },
        },
      ],
    },
  };

  return <Chart options={options} series={series} type="bar" height={350} />;
};

export default MyBarChart;

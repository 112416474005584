import React from 'react';
import ReactApexChart from 'react-apexcharts';

const SummaryChart = ({ cultureFactors = [], outcomeFactors = [] }) => {
  // Combine cultureFactors and outcomeFactors
  const factors = [...cultureFactors, ...outcomeFactors];

  // Check if factors array is empty
  if (factors.length === 0) {
    return <div>No data available to display the chart.</div>;
  }

  // Organize data from highest to lowest current score
  const sortedFactors = factors
    .filter((factor) => typeof factor.latest_score === 'number')
    .sort((a, b) => b.latest_score - a.latest_score);

  // Map over the factors to create data points
  const dataPoints = sortedFactors.map((factor) => {
    const latestScore = factor.latest_score * 10; // Convert to percentage
    const lastScore = parseFloat(factor.last_score) * 10; // Convert to percentage

    // Check if lastScore is a valid number
    const hasPrevious = !isNaN(lastScore);

    // Determine the bar color
    let barColor;
    if (hasPrevious) {
      barColor = latestScore >= lastScore ? '#27CDA7' : '#F15062';
    } else {
      barColor = '#A9A9A9'; // Use gray color when there's no previous score
    }

    return {
      x: factor.factor_name || factor.outcome_name || 'Unnamed Factor', // Use factor_name or outcome_name
      y: Math.round(latestScore), // Round the percentage
      fillColor: barColor, // Custom color per data point
    };
  });

  // Create an array of bar colors for each data point
  const barColors = dataPoints.map((dataPoint) => dataPoint.fillColor);

  // Determine chart min and max
  const latestScores = dataPoints.map((dataPoint) => dataPoint.y);
  const lowestScore = Math.min(...latestScores);
  const highestScore = Math.max(...latestScores);

  const yAxisMin = Math.max(0, lowestScore - 10);
  const yAxisMax = Math.min(100, highestScore + 10);

  const series = [
    {
      name: 'Current',
      data: dataPoints,
    },
  ];

  const options = {
    chart: {
      height: 350,
      type: 'bar',
      fontFamily: 'Raleway, sans-serif', // Set the font to Raleway
      animations: {
        enabled: false, // Disable animations
      },
      toolbar: {
        show: false, // Hide the toolbar (zoom, pan, etc.)
      },
      zoom: {
        enabled: false, // Disable zooming
      },
      selection: {
        enabled: false, // Disable selection
      },
      sparkline: {
        enabled: false, // Ensure sparkline is disabled
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '60%',
        distributed: true, // Allows for per data point colors
        borderRadius: 4, // Add slight bar border rounding
      },
    },
    colors: barColors, // Use custom colors for each bar
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      enabled: false, // Disable tooltips
    },
    states: {
      hover: {
        filter: {
          type: 'none', // Disable hover effects
        },
      },
    },
    legend: {
      show: false, // Hide the legend since we're only showing current scores
    },
    xaxis: {
      type: 'category',
      labels: {
        rotate: -45,
      },
      tooltip: {
        enabled: false, // Disable x-axis tooltips
      },
    },
    yaxis: {
      min: yAxisMin,
      max: yAxisMax,
      labels: {
        formatter: function (value) {
          return Math.round(value).toString(); // No decimal values
        },
      },
    },
  };

  return (
    <div style={{ fontFamily: 'Raleway, sans-serif' }}>
      <div id="chart">
        <ReactApexChart options={options} series={series} type="bar" height={350} />
      </div>
    </div>
  );
};

export default SummaryChart;

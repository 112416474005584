import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import DataLoading from "app/audit/DataLoaders/OtpDataLoader";
//
import OtpSurvey from "otpSurvey";
import OtpReport from "reports/Audit/index";
import Categories from "settings/Categories/index";
import NotSubscribed from "404/NotSubscribed";
import Action from "reports/Action";
import PDFReport from "reports/PDFReport/index";
import OtpPDFReport from "reports/PDFReport/otp";
import CWFPDF from "components/CWFPDF";
import GeneratePDF from "reports/PDFReport/GenerateWindow";
import AI from "reports/AI/index";

import {
  SG_GET_SURVEY_STRUCTURES,
  SG_GET_SURVEY_STANDARD,
  SG_GET_EMPLOYEE_CATEGORIES,
  SG_GET_SURVEY_QUESTIONS,
  SG_GET_OUTCOME_QUESTIONS,
  SG_GET_EMPLOYEE_USER_CATEGORIES,
} from "constants/actions";

//
// import NewRoute from "routes"
//
import Lost from "404";
import SimpleAnalytics from "app/audit/simple/index";

function hasRequiredStructure(data, structure, redirect) {
  const userPermissions = data?.permissions;
  // If the user is not an admin, check if they have access to the page.
  const route = window.location.pathname;
  // use regex to extract everything after /leader/audit
  // const result = route.match(/\.*\/audit(.*)/)?.[1];
  // const permission = PERMISSIONS_MAP.find((p) => p.route === result);

  // if (userPermissions?.account_type !== 5) {
  //   if (
  //     permission &&
  //     userPermissions?.employee_employee_permission?.auth_details &&
  //     !userHasAccess(
  //       userPermissions?.employee_employee_permission?.auth_details,
  //       permission
  //     )
  //   ) {
  //     redirect();
  //     return;
  //   }
  // }

  // Ensure data?.categories[0]?.categories is not undefined.
  if (data?.categories?.[0]?.categories === undefined) {
    return false;
  }

  // Check each top-level property in the structure against the data
  return Object.keys(structure).every((key) => {
    // If the structure type is an array, check if the data is an array
    if (Array.isArray(structure[key])) {
      return Array.isArray(data?.[key]);
    }
    return data?.hasOwnProperty(key);
  });
}

const SURVEY_TYPE = 8;

//
function RoutesOtp() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [organization, setOrganization] = useState(false);
  const [organizationId, setOrganizationId] = useState(false);
  const [validRoute, setValidRoute] = useState(false);
  const [employee, setEmployee] = useState(false);

  // console.log(employee)

  const {
    get_auth,
    get_organizations,
    get_selectedOrg,
    get_employees,
    core_data,
    get_survey_structure,
    get_employee_categories,
    get_survey_questions,
    employeeId,
  } = useSelector(
    (state) => ({
      get_auth: state.auth,
      get_error: state.errors,
      get_organizations: state.organizations,
      get_selectedOrg: state.selectedOrg,
      get_employees: state.employees,
      core_data: state.audit?.core_data,
      get_survey_structure: state.surveystructure.survey_structure,
      employeeId: Number(state.auth?.employee_id),
      get_employee_categories:
        state.employees.userEmp.employee_employee_category,
      get_survey_questions: state.surveyquestions.survey_questions,
    }),
    shallowEqual
  );

  const AmbassadorId = useSelector(
    (state) => Number(state.auth?.ambassador_id),
    shallowEqual
  );
  const EmployeeId = useSelector(
    (state) => state.auth?.employee_id,
    shallowEqual
  );

  useEffect(() => {
    if (Number(AmbassadorId) > 0) {
      setOrganizationId(get_selectedOrg?.organization?.id);
    } else {
      if (Number(EmployeeId)) {
        setEmployee(get_employees.userEmp);
      }
      setOrganizationId(Number(get_auth.organization_id));
    }
  }, [get_selectedOrg, AmbassadorId, get_auth, EmployeeId, get_employees]);

  useEffect(() => {
    if (!get_survey_structure[0] && Number(get_auth.organization_id)) {
      dispatch({
        type: SG_GET_SURVEY_STRUCTURES,
        payload: `organization=${Number(get_auth.organization_id)}`,
      });
    }
  }, [get_auth.organization_id, dispatch, get_survey_structure]);
  useEffect(() => {
    dispatch({
      type: SG_GET_SURVEY_STANDARD,
      payload: `survey_type=${SURVEY_TYPE}`,
    });
  }, [dispatch]);

  // Fetch employee categories
  useEffect(() => {
    if (employeeId && get_employee_categories?.categories?.length === 0) {
      dispatch({
        type: SG_GET_EMPLOYEE_CATEGORIES,
        payload: `employee=${employeeId}`,
      });
    }

    if (employeeId) {
      dispatch({
        type: SG_GET_EMPLOYEE_USER_CATEGORIES,
        payload: `employee=${employeeId}`,
      });
    }
  }, [get_auth, dispatch]);

  useEffect(() => {
    if (SURVEY_TYPE && get_survey_questions?.length === 0) {
      dispatch({
        type: SG_GET_SURVEY_QUESTIONS,
        payload: `survey_type=${SURVEY_TYPE}&status=2`,
      });
    }
  }, [dispatch, SURVEY_TYPE]);

  useEffect(() => {
    if (organizationId) {
      setOrganization(get_organizations[organizationId]);
    } else {
      setOrganization(false);
    }
  }, [organizationId, get_organizations]);

  useEffect(() => {
    const Found = organization?.services_enabled
      ?.filter((srv) => srv?.enabled === true)
      .map((sr) => sr.name);

    setValidRoute(Found);
  }, [organization]);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      if (!validRoute?.includes("OTP")) {
        return navigate("/app/unsubscribed");
      }
    }, 2000);
    return () => clearTimeout(timer1);
  }, [organization, validRoute, navigate]);

  useEffect(() => {
    // role < 7
    // account_type < 9
    if (validRoute) {
      if (validRoute?.includes("Teamtool")) {
        if (employee?.account_type > 7) {
          if (employee?.role > 7) {
            return navigate("/app/access-denied");
          }
        }
      }
    }
  }, [employee, validRoute, navigate]);

  // Here we create the required data structure to validate the data
  const dataStructure = {
    categories: [],
    questions: null,
    get_standards: null,
  };

  // Array of routes where OtpDataLoader should run
  const routesRequiringLoader = [
    "/app/otp/dashboard",
    "/app/otp/feedback",
    "/app/otp/ai",
  ];

  return (
    <>
      {/* Only show DataLoading on specific routes */}
      {routesRequiringLoader.includes(location.pathname) &&
        !hasRequiredStructure(core_data, dataStructure, () =>
          navigate("/404")
        ) && <DataLoading />}

      {validRoute && (
        <Routes>
          <Route path="/" element={<OtpSurvey />} />
          <Route path={"dashboard"} element={<SimpleAnalytics />} />
          <Route path={"feedback"} element={<SimpleAnalytics stage={4} />} />
          <Route path={"report"} element={<OtpReport />} />
          <Route path={"category"} element={<Categories />} />
          <Route path={"action"} element={<Action />} />
          <Route path={"*"} element={<Lost />} />
          <Route path={"pdf"} element={<OtpPDFReport />} />
          <Route path={"pdf2"} element={<CWFPDF />} />
          <Route path={"generate-pdf"} element={<GeneratePDF />} />
          <Route path={"ai"} element={<AI />} />
        </Routes>
      )}
    </>
  );
}

export default RoutesOtp;

import styled, { keyframes } from "styled-components";
import React, { useEffect, useCallback, useState, useRef } from "react";
import Questions from "./Questions";
import Chart from "react-apexcharts";
import {
  generate_chart_standards_data,
  generate_chart_factor_standards,
  generate_question_standards,
  generate_outcome_question_standards,
} from "../calculations";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { SET_NEW_NAVIGATION } from "constants/actions";
import Empty from "./components/Empty";
import { filter_data, get_overall_average } from "reports/Tag/calculations";
import Navigation from "./Navigation";
import AICopilot from "./components/AICopilot";

const Benchmark = ({
  data,
  standards,
  navigation,
  categories,
  questions,
  outcomeStandards,
  rule,
  outcomeQ,
  core_data,
}) => {
  const dispatch = useDispatch();
  const [series, setSeries] = useState(null);
  const [options, setOptions] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [factorData, setFactorData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [overallScore, setOverallScore] = useState();
  const [filteredData, setFilteredData] = useState();
  const [AIChatOpen, setAIChatOpen] = useState(false);

  console.log(core_data)

  const { selectedFilters, latestData } = useSelector(
    (state) => ({
      selectedFilters: state.audit?.new_filters,
      latestData: state.audit?.filtered_data,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (latestData && latestData?.length > 0) {
      let filteredData;
      if (latestData?.[0]?.[0]) {
        filteredData = filter_data(latestData[0], selectedFilters);
      } else {
        filteredData = filter_data(latestData, selectedFilters);
      }
      if (
        (Array.isArray(filteredData[0]) && filteredData[0].length > 0) ||
        (!Array.isArray(filteredData[0]) && filteredData.length > 0)
      ) {
        setFilteredData(filteredData);
        setOverallScore(get_overall_average(filteredData).toFixed(1));
      }
    }
  }, [latestData, selectedFilters]);

  useEffect(() => {
    // Set the navigation to the default value
    const defaultNav = {
      selected1: null,
      selected2: null,
      selected3: null,
      factor: null,
      outcome: {
        name: "Culture",
        id: 0,
      },
    };
    dispatch({
      type: SET_NEW_NAVIGATION,
      payload: defaultNav,
    });
  }, []);

  // Data to be displayed when overview is selected and culture
  useEffect(() => {
    if (filteredData && filteredData.length > 0) {
      if (
        navigation?.outcome?.id > 0 &&
        !navigation?.sub_category &&
        navigation?.factor?.id
      ) {
        let data_type = navigation?.outcome;
        // We need to create a new function to map the outcomes question standards
        // to the factorData

        if (navigation.factor?.id) {
          setFactorData(
            generate_outcome_question_standards(
              filteredData,
              outcomeStandards,
              navigation,
              outcomeQ,
              rule
            )
          );
          return;
        }
      }

      if (navigation?.outcome && !navigation.sub_category?.id && filteredData) {
        let data_type = navigation?.outcome;

        // if (data_type?.id > 0 && !outcomeStandards?.overall_average) {
        //   setChartData(null);
        //   return;
        // }

        setChartData(
          generate_chart_standards_data(
            filteredData,
            standards,
            navigation,
            categories,
            data_type,
            outcomeStandards,
            rule
          )
        );
        return;
      }

      if (navigation.sub_category?.id) {
        let data_type = navigation?.outcome;
        // if (data_type?.id > 0 && !outcomeStandards?.overall_average) {
        //   setChartData(null);
        //   return;
        // }

        if (navigation.factor?.id) {
          setFactorData(
            generate_question_standards(
              filteredData,
              standards,
              navigation,
              categories,
              questions,
              rule
            )
          );
          return;
        }

        if (
          standards?.id &&
          (navigation.sub_category?.id ||
            navigation.sub_category?.name == "Factor overview")
        ) {
          // let data_type = navigation?.outcome;
          // if (!outcomeStandards?.overall_average) {
          //   setChartData(null);
          //   return;
          // }
          setChartData(
            generate_chart_factor_standards(
              filteredData,
              standards,
              navigation,
              categories,
              data_type,
              questions,
              rule
            )
          );

          return;
        }
      }
    }
  }, [standards, navigation, categories, filteredData]);

  useEffect(() => {
    if (chartData) {
      setLoading(true);
      let sorted_data = chartData.sort((a, b) => (b.diff > a.diff ? 1 : -1));
      let _series = [
        {
          data: sorted_data.map((item) => {
            return {
              x: item.name,
              y: item.diff === 0 ? 0.1 : item.diff.toFixed(1),
              id: item.option_id,
            };
          }),
        },
      ];

      let _options = data3.options;
      _options.colors = sorted_data.map((item) =>
        item.diff >= 0 ? "#27CDA7" : "#F15062"
      );
      _options.xaxis.categories = sorted_data.map((item) => item.name);

      let max = Math.max(...sorted_data.map((item) => item.diff)) + 5;
      let min = Math.min(...sorted_data.map((item) => item.diff));

      _options.yaxis.max = max > 0 ? max : 0;

      _options.yaxis.min = min < 0 ? min : 0;

      _options.dataLabels = {
        enabled: true,
        formatter: function (val, opt) {
          if (sorted_data?.[opt?.dataPointIndex]?.diff === 0) {
            return "0";
          }
          return val;
        },
      };

      _options.chart.events = {
        dataPointSelection: (event, chartContext, config) => {
          let nav = { ...navigation };
          if (navigation?.sub_category?.name !== "Overview") {
            nav.factor = {
              name: sorted_data[config.dataPointIndex].name,
              id: sorted_data[config.dataPointIndex].option_id,
              dimension: sorted_data[config.dataPointIndex].dimension,
              type: "factor",
            };
          } else {
            nav.sub_category = {
              name: sorted_data[config.dataPointIndex].name,
              id: sorted_data[config.dataPointIndex].option_id,
              type: "cat_option",
            };
          }

          dispatch({
            type: SET_NEW_NAVIGATION,
            payload: nav,
          });
        },
      };

      setSeries(_series);
      setOptions(_options);
      setTimeout(() => {
        setLoading(false);
      }, [1000]);
    }
  }, [chartData, selectedFilters]);

  const renderChart = () => {
    if (loading) {
      return <div>Loading...</div>;
    }

    if (navigation.factor?.name && navigation.factor?.name != "Overview") {
      return (
        <>
          <Questions
            data={factorData}
            navigation={navigation}
            setAIChatOpen={setAIChatOpen}
            read_feedback={core_data?.permissions?.read_feedback}
          />
        </>
      );
    }

    return <Chart options={options} series={series} type="bar" width={800} />;
  };

  const hasNoData = chartData?.filter((item) => !isNaN(item.diff)).length === 0;
  return (
    <>
      {AIChatOpen && (
        <AICopilot
          open={AIChatOpen}
          setOpen={setAIChatOpen}
          navigation={navigation}
        />
      )}
      <Container>
        <Title>Differences in benchmarks</Title>
        <Description>
          {/* According to your collected data, the average culture score for your
        organization is {overallScore}. The following chart shows the difference */}
          The following chart shows the difference between the average score for
          each area and the global benchmark.
        </Description>

        <Navigation outcomes={outcomeQ} core_data={core_data} />

        {(!loading && (!chartData || !core_data?.standards?.id || hasNoData)) ||
        core_data?.noData ? (
          <Empty />
        ) : (
          renderChart()
        )}
      </Container>
    </>
  );
};

export default Benchmark;

function adjustMin(max, min) {
  // Calculate the initial difference
  let difference = max - min;

  // Find the nearest lower multiple of 10 for the difference, if necessary
  let adjustedDifference = Math.floor(difference / 10) * 10;

  // Adjust min only if it makes min smaller
  min = max - adjustedDifference;

  return min;
}
const fadeIn = keyframes`

    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const Container = styled.div`
  width: 100%;
`;

const IMG2 = styled.img`
  width: 20px;
  margin-left: 10px;
  margin-bottom: -5px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const AIButton = styled.div`
  background: #2d70e2;
  font-family: "Raleway", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    background: #333;
  }
`;

const Description = styled.div`
  margin-bottom: 30px;
  line-height: 1.4;
`;

const Help = styled.div`
  display: inline;
  padding: 5px 10px;
  border: 1px solid #666d79;
  color: #666d79;
  font-size: 12px;
  font-family: "Raleway";
  margin-bottom: 10px;
  position: absolute;
  right: 0px;
  top: 10px;
  cursor: pointer;
`;

const data3 = {
  series: [
    {
      data: [10, 8, 4.4, -3, -8, -20, -22],
    },
  ],
  options: {
    chart: {
      type: "bar",
      fontFamily: "Poppins, sans-serif",
      foreColor: "#7E7E7E",
      toolbar: {
        show: false,
      },
      width: 800,
      height: 400,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          if (val === 0.1) {
            return "0%";
          }
          return val + "%";
        },
        title: {
          formatter: function (seriesName) {
            return "";
          },
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      show: false,
    },
    grid: {
      padding: {
        bottom: 60,
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
        dataLabels: {
          // Position the label in the center of the bar
          position: "center",
          style: {
            fontSize: "12px",
            colors: ["#000000"],
          },
        },
      },
    },
    colors: [
      "#27CDA7",
      "#27CDA7",
      "#27CDA7",
      "#F15062",
      "#F15062",
      "#F15062",
      "#F15062",
    ],
    xaxis: {
      categories: [
        "Department A",
        "Department B",
        "Department C",
        "Department D",
        "Department E",
        "Department F",
        "Department G",
      ],
      tickPlacement: "on",
      labels: {
        style: {
          fontSize: "9px",
          colors: "#2D70E2",
        },
        rotateAlways: true,
        maxHeight: 200,
      },
    },
    yaxis: {
      min: 0,
      max: 20,
      decimalsInFloat: 1,
      forceNiceScale: true,
      title: {
        text: "Difference from the benchmark average",
        style: {
          fontWeight: 400,
        },
      },
    },
  },
};

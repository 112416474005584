import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Dimmer, Loader, Modal, Checkbox } from "semantic-ui-react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import DialScore from "./components/DialScore";
import ScoreCard from "./components/ScoreCard";

import Down from "assets/images/UI/down-small.svg";
import Up from "assets/images/UI/up-small.svg";
import { ideaIcon, heartIcon, starIcon, closeIcon } from "./common/icons";
import CultureMap from "./components/CultureMap";
import Anchor from "./Anchor";
import {
  getAllDataByQuarter,
  getAnchorAveragesByQuestion,
} from "reports/Outcomes/calculations";
import Chart from "react-apexcharts";
import Filters from "./components/Filters";
import { filter_data, get_overall_average } from "reports/Tag/calculations";
import Empty from "./components/Empty";
// Calculations
import {
  calculate_table_data,
  getSortOrders,
  restructure_Questions,
} from "reports/Dashboard/calculations.js";
import RadioButtonGroup from "./components/RadioButton";
import PermissionsModal from "./PermissionsModal";
import { SET_NEW_FILTERS } from "constants/actions";
import { calculateOtpCardAverages } from "../calculations";

const colorScheme = [
  "#F05A12",
  "#86E5CB",
  "#2D70E2",
  "#C293FF",
  "#F7E68F",
  "#FF9066",
  "#C4DAFF",
  "#FEA711",
  "#FFD3B6",
  "#B5EAD7",
  "#FCDDAA",
];

function getPosition(id, data) {
  return data.findIndex((item) => item.id === id) + 1;
}

// Create a function that takes the data and creates a new array of objects with the following properties:
// - option
// - n (groupSize)
// - outcomes (an array with the three outcomes and their respective overallScores, and ids, and labels);
// The function should return the new array of objects.
function transformData(data) {
  // Remove any undefined objects from the array
  data = data.filter((item) => item);

  return data.map(
    ({ option, groupSize, outcomesCalculations, totalRespondants }) => ({
      id: option?.id,
      option: option?.name,
      n: groupSize || totalRespondants,
      outcomes: outcomesCalculations.map(
        ({ id, outcomeLabel, overallScore }) => ({
          id,
          label: outcomeLabel,
          overallScore,
        })
      ),
    })
  );
}

// Next create a function that takes 2 parameters, the data and an id of an outcome.
// The function should return 2 objects, the one with the highest overallScore for the outcome and the one with the lowest overallScore for the outcome.
function getMinMaxOutcome(transformedData, outcomeId, outcomesData) {
  let highestParentOption = null;
  let lowestParentOption = null;
  let highestAverage = Number.NEGATIVE_INFINITY;
  let lowestAverage = Number.POSITIVE_INFINITY;
  const availableOptions = outcomesData?.uniqueLocationData?.map(
    (item) => item.option
  );

  transformedData.forEach(({ option, outcomes, id }) => {
    const outcome = outcomes.find(({ id }) => id === outcomeId);

    if (!availableOptions.includes(id)) {
      return;
    }
    if (outcome) {
      const average = outcome.overallScore;
      if (average > highestAverage) {
        highestAverage = average;
        highestParentOption = option;
      }
      if (average < lowestAverage) {
        lowestAverage = average;
        lowestParentOption = option;
      }
    }
  });

  return [highestParentOption, lowestParentOption];
}

// Create another function which takes 2 parameters, the data and an id of an outcome.
// The function should return the average overallScore for that outcomeId.
// Averages should be weighted by the groupSize.

function getAverageOutcome(data, outcomeId) {
  // Flatten and filter valid outcomes (ignore ones with score of 0)
  const outcomes = data.flatMap(({ outcomes, n }) =>
    outcomes
      .filter((outcome) => outcome.overallScore !== 0) // Ignore scores of 0
      .map((outcome) => ({ ...outcome, n }))
  );

  // Filter by the outcomeId
  const outcome = outcomes.filter(({ id }) => id === outcomeId);

  // Calculate the sum and n while ensuring valid data
  const sum = outcome.reduce(
    (acc, curr) => acc + curr.overallScore * curr.n,
    0
  );
  const n = outcome.reduce((acc, curr) => acc + curr.n, 0);

  // If no valid entries, return 0 to prevent NaN
  if (n === 0) return 0;

  // Return the calculated average
  return sum / n;
}

const options = {
  chart: {
    type: "bar",
    fontFamily: "Poppins, sans-serif",
    foreColor: "#7E7E7E",
    toolbar: {
      show: false,
    },
    width: 600,
    height: 500,
    events: {},
  },
  tooltip: {
    enabled: true,
    custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      return "<div></div>";
    },
  },
  legend: {
    position: "top",
    horizontalAlign: "left",
    show: false,
    markers: {
      fillColors: ["#468ADE", "#E6E6E6"],
    },
  },
  plotOptions: {
    bar: {
      distributed: true,
    },
  },
  colors: ["#2D70E2", "#C7CFDB", "#E6E6E6"],
  fill: {
    type: ["solid"],
  },
  xaxis: {
    categories: ["Your Team", "Your Organization"],
    tickPlacement: "on",
    labels: {
      style: {
        fontSize: "9px",
      },
      rotateAlways: true,
      minHeight: 200,
    },
  },
  yaxis: {
    min: 0,
    max: 100,
    forceNiceScale: true,
    decimalsInFloat: 0,
    title: {
      text: "Culture Score",
      style: {
        fontWeight: 400,
      },
    },
  },
};

function filterStructureByUserCategories(structure, userCategories) {
  let result = [];

  // Iterate over categories in the structure
  structure.categories.forEach((category) => {
    // Check if the category matches any userCategory
    userCategories.forEach((userCategory) => {
      if (category.id === userCategory.name_id) {
        // Iterate over options within the category
        category.options.forEach((option) => {
          // If option name or value_id matches the user category value or value_id
          if (
            option.id === userCategory.value_id ||
            option.name === userCategory.value
          ) {
            result.push({
              id: option.id,
              name: option.name,
              color: option.color,
            });
          }

          // Check subfactors within the option
          option.subfactors.forEach((subfactor) => {
            if (
              subfactor.id === userCategory.value_id ||
              subfactor.name === userCategory.value
            ) {
              result.push({
                id: subfactor.id,
                name: subfactor.name,
                color: subfactor.color,
              });
            }
          });
        });
      }
    });
  });

  return result;
}

const calculateScreenData = (
  data,
  structure,
  anchor,
  outcmesQuestions,
  rule,
  selectedFilters,
  isOTP,
  surveyStructure,
  core_data
) => {
  let subfactorsSelected = selectedFilters?.filter(
    (filter) => filter.isSubfactor
  );

  const isPrimaryAnchor =
    structure[0]?.categories?.find((cat) => cat.id === anchor)?.priority ===
    "primary";

  let anchorOptions = structure[0]?.categories?.find(
    (cat) => cat.id === anchor
  )?.options;

  if (anchorOptions?.length === 0 && isOTP && surveyStructure?.[0]) {
    // if there are no anchor options and it is OTP, then the account
    // is likely culture leader and therefore needs to get the categories from
    // core_data?.employeeCategories
    subfactorsSelected = filterStructureByUserCategories(
      surveyStructure[0],
      core_data?.employeeCategories?.category
    );
  }

  const groupedData =
    isPrimaryAnchor && subfactorsSelected?.length > 0
      ? subfactorsSelected.map((subfactor) => {
          return {
            option: subfactor,
            data: data.filter(
              (data) =>
                data.categories?.find((cat) => cat.id === anchor)?.subfactor ===
                subfactor.id
            ),
          };
        })
      : anchorOptions.map((option) => {
          return {
            option,
            data: data.filter(
              (data) =>
                data.categories?.find((cat) => cat.id === anchor)?.response ===
                option.id
            ),
          };
        });

  const filteredGroups = groupedData.filter(
    (group) => group.data.length > rule
  );

  const anchorData = anchorOptions.map((option) => {
    return {
      option,
      data: data.filter(
        (data) =>
          data.categories?.find((cat) => cat.id === anchor)?.response ===
          option.id
      ),
    };
  });

  const finalData = filteredGroups.map((group) => {
    const outcomesCalculations = [];
    const factorCalculations = {};

    const outcomesData = group.data.flatMap((data) => data.employee_outcomes);
    if (outcomesData.length === 0) {
      return;
    }

    const outcomesQuestions = outcmesQuestions?.questions.map((outcome, i) => {
      return {
        id: outcome.id,
        outcomeLabel: outcome.name,
        index: i,
      };
    });

    outcomesQuestions?.forEach((outcome) => {
      let filteredOutcomes = outcomesData?.flatMap((data) =>
        data.responses?.filter(
          (d) =>
            Number(d?.q) === Number(outcome.id) &&
            (d?.clicked === true || d?.clicked === undefined)
        )
      );

      filteredOutcomes = filteredOutcomes.filter((item) => item);


      const totalSum = filteredOutcomes?.reduce((acc, curr) => {
        if (curr?.response) {
          return acc + curr?.response;
        } else {
          return acc;
        }
      }, 0);

      const overallScore = filteredOutcomes?.length
        ? totalSum / filteredOutcomes.length
        : 0;

      if (overallScore !== 0) {
        outcomesCalculations.push({
          id: outcome.id,
          outcomeLabel: outcome.outcomeLabel,
          overallScore,
        });
      }
    });

    group.data.forEach((data) => {
      data.questions.forEach((question) => {
        const dimension = question.id;
        const factor = question.factor;
        const score = question.response;

        if (!factorCalculations[dimension]) {
          factorCalculations[dimension] = {};
        }

        if (!factorCalculations[dimension][factor]) {
          factorCalculations[dimension][factor] = [];
        }

        // if score is a number, push it to the array
        if (typeof score === "number") {
          factorCalculations[dimension][factor].push(score);
        }
      });
    });

    const calculatedFactors = Object.entries(factorCalculations).map(
      ([dimension, factors]) => {
        return {
          dimension: Number(dimension),
          factors: Object.entries(factors).map(([factor, scores]) => {
            const totalScore = scores.reduce((acc, score) => acc + score, 0);
            const averageScore = scores.length ? totalScore / scores.length : 0;
            return {
              factor: Number(factor),
              averageScore,
              // Get a list of scores for each anchorOption
              outcomesCalculations: anchorData.map((anchor) => {
                const anchorScores = anchor.data
                  .flatMap((data) =>
                    data.questions.filter(
                      (question) =>
                        Number(question.id) === Number(dimension) &&
                        Number(question.factor) === Number(factor)
                    )
                  )
                  ?.filter((question) => !isNaN(question.response));

                const totalAnchorScore = anchorScores.reduce(
                  (acc, score) => acc + score.response,
                  0
                );
                const averageAnchorScore = anchorScores.length
                  ? totalAnchorScore / anchorScores.length
                  : 0;
                const totalRespondants = anchorData.reduce((acc, obj) => {
                  return acc + obj.data.length;
                }, 0);

                const anchorRespondants = anchor.data.length;

                return {
                  label: anchor.option.name,
                  totalRespondants: totalRespondants,
                  anchorRespondants,
                  average: Math.round(averageAnchorScore * 10),
                };
              }),
            };
          }),
        };
      }
    );

    const overallScore = get_overall_average(group.data);
    const groupLength = group?.data?.length;

    return {
      option: group.option,
      label: group.option.name,
      overallScore,
      outcomesCalculations,
      factorCalculations: calculatedFactors,
      overallSpread: groupLength > 4 ? groupLength * 1.2 : 5,
      groupSize: groupLength,
    };
  });

  const preFilteredData = groupedData.map((group) => {
    const outcomesCalculations = [];

    const outcomesData = group.data.flatMap((data) => data.employee_outcomes);
    if (outcomesData.length === 0) {
      return;
    }
    const outcomesQuestions = outcmesQuestions?.questions.map((outcome) => {
      return {
        id: outcome.id,
        outcomeLabel: outcome.name,
      };
    });

    outcomesQuestions?.forEach((outcome) => {
      let filteredOutcomes = outcomesData?.flatMap((data) =>
      data.responses?.filter(
        (d) =>
          Number(d?.q) === Number(outcome.id) &&
          (d?.clicked === true || d?.clicked === undefined)
      )
    );

    filteredOutcomes = filteredOutcomes.filter((item) => item);

      const totalSum = filteredOutcomes.reduce((acc, curr) => {
        if (curr?.response) {
          return acc + curr.response;
        } else {
          return acc;
        }
      }, 0);
      const overallScore = filteredOutcomes.length
        ? totalSum / filteredOutcomes.length
        : 0;

      if (overallScore !== 0) {
        outcomesCalculations.push({
          id: outcome.id,
          outcomeLabel: outcome.outcomeLabel,
          overallScore,
        });
      }
    });

    const overallScore = get_overall_average(group.data);
    const totalRespondants = group.data.length;

    return {
      option: group.option,
      label: group.option.name,
      overallScore,
      outcomesCalculations,
      totalRespondants,
    };
  });

  return { finalData, preFilteredData };
};

// Create a array for a particular outcome id, this will be used to get the rank.
function createOutcomesArray(data, outcomeId) {
  const newArray = [];
  data.forEach((item) => {
    item.outcomesCalculations.forEach((outcome) => {
      if (outcome.id === outcomeId) {
        const newObj = {
          label: item.label,
          id: item.option.id,
          outcome: outcome.outcomeLabel,
          outcomeId: outcome.id,
          overallScore: outcome.overallScore,
        };
        newArray.push(newObj);
      }
    });
  });

  newArray.sort((a, b) => b.overallScore - a.overallScore);

  return newArray;
}

function getRankAndTotal(newArray, id) {
  const rank = newArray.findIndex((item) => item.id === id) + 1;
  const total = newArray.length;
  return {
    rank,
    total,
  };
}

function getChange(curr, prev, currId) {
  const prevItem = prev?.find((item) => item.option.id === currId);
  const outcomesCalculations = prevItem?.outcomesCalculations;
  const outcome = outcomesCalculations?.find(
    (outcome) => outcome.id === curr.id
  );
  const change = curr.overallScore - outcome?.overallScore;
  if (outcome?.overallScore === 0) return 0;

  return change;
}

function getOutcomeScoresAvg(outcomeId, optionId, data) {
  const outcome = data.find((outcome) => outcome.id === outcomeId);
  const currentScores = outcome.scores.currentScores.find(
    (score) => score.option === optionId
  );
  const changeScores = outcome.scores.changeScores.find(
    (score) => score.option === optionId
  );

  return {
    currentScores,
    changeScores,
  };
}

const getFactorLabel = (dimension, factorId, questions) => {
  const dimensionData = questions?.dimensions?.find(
    (d) => d.id - 1 === dimension
  );
  const factor = dimensionData?.factors?.find((f) => f.id - 1 === factorId);
  return factor;
};

const getOutcomesMap = (outcomes) => {
  let filteredData = outcomes.filter((obj) => obj.average !== 0);
  // sort by highest average
  filteredData.sort((a, b) => b.average - a.average);
  filteredData.forEach((obj, index) => {
    obj.groupRank = index + 1;
    obj.totalGroups = filteredData.length;
  });
  return filteredData;
};

// Creates chart data using finalData
const restructureData = (
  data,
  prevData,
  calculatedOutcomes,
  isOtp,
  questions
) => {
  return data.map((item, index) => {
    const outcomes = !isOtp
      ? item.outcomesCalculations.map((outcome) => {
          const outcomesSorted = createOutcomesArray(
            data,
            outcome?.id,
            calculatedOutcomes
          );
          const rankInfo = getRankAndTotal(outcomesSorted, item?.option?.id);

          const avgData = getOutcomeScoresAvg(
            outcome?.id,
            item?.option?.id,
            calculatedOutcomes
          );

          return {
            id: Number(outcome.id),
            label: outcome.outcomeLabel,
            average: Math.round(avgData?.currentScores?.average * 10),
            totalGroups: rankInfo?.total,
            groupRank: rankInfo?.rank,
            anchorOption: item.label,
            outcomeChange: Math.round(avgData?.changeScores?.change * 10) || 0,
          };
        })
      : item.factorCalculations.map((dimension) => {
          return dimension.factors.map((factor) => {
            const factorLabel = getFactorLabel(
              dimension.dimension,
              factor.factor,
              questions
            );

            const mappedOutcomes = getOutcomesMap(factor.outcomesCalculations);

            return {
              id: Number(factor.factor),
              label: factorLabel?.title,
              average: Math.round(factor.averageScore * 10),
              anchorOption: item.label,
              outcomes: mappedOutcomes,
              outcomeChange: 0,
              totalGroups: 0,
              totalRespondants: mappedOutcomes?.[0]?.totalRespondants,
              groupRank: 0,
            };
          });
        });

    // Get the overall change from previous
    const prevItem = prevData?.filter(
      (prevItem) => prevItem.option.id === item.option.id
    );

    const overallChange = item?.overallScore - prevItem?.[0]?.overallScore;

    return {
      category: item.label,
      x: Math.round(item.overallScore * 10),
      y: !isNaN(overallChange) ? overallChange * 10 : 0,
      id: item.option.id,
      // Spread is now length of data
      size: Math.abs(item?.overallSpread) || 5,
      totalRespondants: item?.groupSize,
      // Use the index as the color, if the index is greater than the colorScheme length, then use the remainder of the index divided by the colorScheme length.
      color: colorScheme[index % colorScheme.length],
      outcomes,
    };
  });
};

// Loop through the locationData starting from the reverse
// For each locationData add the object to a new array, only if the option hasn't already been added.
const getUniqueLocationData = (data) => {
  const uniqueLocationData = [];
  for (let i = data.length - 1; i >= 0; i--) {
    const locationData = data[i].locationData;
    for (let j = locationData.length - 1; j >= 0; j--) {
      const option = locationData[j].option;
      const optionExists = uniqueLocationData.some(
        (location) => location.option === option
      );
      if (!optionExists && locationData[j]?.average !== 0) {
        uniqueLocationData.push(locationData[j]);
      }
    }
  }

  // Order list from highest average to lowest.
  uniqueLocationData.sort((a, b) => b.average - a.average);

  return uniqueLocationData;
};

// Using prioritiesToFilterStructure and surveyStructureFiltered
// find the matching priorities and from the surveyStructureFiltered?.categories?.id
// and filter out any of the options that are not in the prioritiesToFilterStructure?.values array.
// If the prioritiesToFilterStructure?.values array is empty, then return the surveyStructureFiltered
// as is.
const filterSurveyStructure = (
  surveyStructureFiltered,
  prioritiesToFilterStructure
) => {
  if (prioritiesToFilterStructure?.length === 0) {
    return surveyStructureFiltered;
  }
  const filteredCategories = surveyStructureFiltered?.[0]?.categories?.map(
    (category) => {
      const permissionsToFilter = prioritiesToFilterStructure?.find(
        (priority) => {
          return priority?.priority === category?.id;
        }
      );
      if (!permissionsToFilter) {
        return category;
      }

      const filteredOptions = category?.options?.filter((option) => {
        return permissionsToFilter?.values?.includes(option?.id);
      });

      const updatedCategory = { ...category };
      updatedCategory.options = filteredOptions;
      return {
        ...category,
        options: filteredOptions,
      };
    }
  );

  const newSurveyStructureFiltered = {
    ...surveyStructureFiltered[0],
    categories: filteredCategories,
  };

  return [newSurveyStructureFiltered];
};

const getIcon = (factor) => {
  switch (factor) {
    case "Engagement":
      return ideaIcon();
    case "Emotional Intelligence":
      return heartIcon();
    case "Psychological Safety":
      return starIcon();
    default:
      return ideaIcon();
  }
};

// Uses get_culture_audit_reports,  go through reportData?.response and filter out the responses that do not meet the prioritiesToFilterStructure
// the responses represent a survey respondant and the goal is to filter out any respondants that do not meet the prioritiesToFilterStructure
export const filterReportResponses = (
  reportData,
  prioritiesToFilterStructure
) => {
  if (prioritiesToFilterStructure?.length === 0) {
    return reportData;
  }

  const filteredSurveys = reportData?.response?.map((survey) => {
    const filteredResponses = survey?.responses?.filter((response) => {
      return prioritiesToFilterStructure?.every((priority) => {
        const priorityId = priority?.priority;
        const priorityValues = priority?.values;
        const matchingResponse = response?.response?.categories?.find(
          (category) => {
            return (
              category?.id === priorityId &&
              priorityValues?.includes(category?.response)
            );
          }
        );
        return matchingResponse;
      });
    });
    const updatedSurvey = { ...survey };
    updatedSurvey.responses = filteredResponses;
    return updatedSurvey;
  });
  reportData.response = filteredSurveys;
  return reportData;
};

const getLabel = (id, anchor, categories) => {
  const category = categories.find((cat) => cat.id === anchor);
  const selectedCat = category.options.find((opt) => opt.id === id);
  return selectedCat ? selectedCat.name : null;
};

function calculateOverallX(data) {
  let sum = 0;
  let totalSize = 0;
  for (let i = 0; i < data.length; i++) {
    sum += data[i].x * data[i].size;
    totalSize += data[i].size;
  }
  return sum / totalSize;
}

function processScores(data) {
  // Helper function to sort quarters
  function quarterToIndex(quarter) {
    const quarters = ["q1", "q2", "q3", "q4"];
    return quarters.indexOf(quarter);
  }

  // Sort data by year and quarter (from oldest to newest for proper comparisons)
  data.sort(
    (a, b) =>
      a.year - b.year || quarterToIndex(a.quarter) - quarterToIndex(b.quarter)
  );

  const currentScores = [];
  const previousScoresMap = {};
  const changeScores = [];

  // Process each data entry
  for (let i = 0; i < data.length; i++) {
    const entry = data[i];
    const locationData = entry.locationData;

    locationData.forEach((loc) => {
      const option = loc.option;
      const average = parseFloat(loc.average);

      // Only consider non-zero averages
      if (average !== 0) {
        if (previousScoresMap[option]) {
          const previousAverage = previousScoresMap[option].average;
          const change = average - previousAverage;

          // Only push if there's a valid change (comparison with past value)
          changeScores.push({
            option: option,
            change: change.toFixed(1), // Round to one decimal place
            label: loc.optionLabel,
          });

          // Update the map to track current quarter's average for future comparisons
          previousScoresMap[option].average = average;
        } else {
          // No previous data, so initialize tracking
          previousScoresMap[option] = { average, label: loc.optionLabel };
        }

        // Update current scores array
        const currentIndex = currentScores.findIndex(
          (item) => item.option === option
        );
        if (currentIndex === -1) {
          currentScores.push({
            option: option,
            average: average,
            label: loc.optionLabel,
          });
        }
      }
    });
  }

  return { currentScores, changeScores };
}

const Analytics = () => {
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState("Loading");
  const [reportData, setReportData] = useState();
  const [filterArray, setFilterArray] = useState([]);
  const [sortOrderList, setSortOrderList] = useState();
  const [selectedSortOrder, setSortOrder] = useState();
  const [selectedSurvey, setSelectedSurvey] = useState();
  const [surveysList, setSurveysList] = useState();
  const [cultureScore, setCultureScore] = useState();
  const [cultureChange, setCultureChange] = useState();
  const [quarterlyData, setQuarterlyData] = useState();
  const [calculated, setCalculated] = useState();
  const [outcomesData, setOutcomesData] = useState();
  const [calculatedOutcomes, setCalculatedOutcomes] = useState();
  const [outcomesQuestions, setOutcomesQuestions] = useState();
  const [selectedChartItem, setSelectedChartItem] = useState();
  const [calculatedChartData, setCalculatedChartData] = useState();
  const [barChartData, setBarChartData] = useState();
  const [screenData, setScreenData] = useState();
  const [lastScreenData, setLastScreenData] = useState();
  const [filteredLastData, setFilteredLastData] = useState();
  const [filteredLatestData, setFilteredLatestData] = useState();
  const [radioOptions, setRadioOptions] = useState();
  const [pageFirstLoad, setPageFirstLoad] = useState(true);
  const [calculatedFactorChartData, setCalculatedFactorChartData] = useState();
  const [useBarGraph, setUseBarGraph] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState(0);

  const dispatch = useDispatch();

  const {
    anchor,
    get_debrief_schedules,
    responses,
    organization,
    outcomesQuestionsList,
    get_survey_questions,
    latestData,
    lastData,
    selectedFilters,
    core_data,
    get_survey_structure,
  } = useSelector(
    (state) => ({
      anchor: state.audit?.anchor,
      get_survey_structure: state.surveystructure.survey_structure,
      get_debrief_schedules: state.debrief_schedule,
      organization: state.organizations,
      outcomesQuestionsList: state.surveyquestions.outcome_questions,
      responses: state.debrief_schedule.culture_audit_reports,
      WhiteLabel: state.white_label?.white_label,
      get_survey_questions: state.surveyquestions.survey_questions,
      selectedFilters: state.audit?.new_filters,
      latestData: state.audit?.filtered_data,
      lastData: state.audit?.filtered_last_data,
      core_data: state.audit?.core_data,
    }),
    shallowEqual
  );

  useEffect(() => {
    // check if the page is OTP or sport-law.
    const isOtpOption =
      window.location.pathname.includes("otp/") ||
      core_data?.standards?.question_sort_order?.[0] === 107;

    if (isOtpOption) {
      setUseBarGraph(isOtpOption);
      setSelectedRadio(isOtpOption ? 1 : 0);
    }
  }, [core_data?.standards]);

  const isOtp =
    window.location.pathname.includes("otp/") ||
    core_data?.standards?.question_sort_order?.[0] === 107;

  const orgRule = !!core_data?.Whitelabel?.rule
    ? Number(core_data?.Whitelabel?.rule)
    : 2;

  useEffect(() => {
    if (responses?.response?.length > 0) {
      let data = getAllDataByQuarter(responses?.response);
      setQuarterlyData(data);
    } else {
      setQuarterlyData([]);
    }
  }, [responses, selectedFilters]);

  useEffect(() => {
    let radioOptions = [];
    radioOptions.push({
      key: "1",
      name: "Culture",
      value: 0,
    });
    if (outcomesQuestions) {
      outcomesQuestions?.questions?.forEach((question) => {
        radioOptions.push({
          key: question.id,
          name: question.name,
          value: Number(question.id),
        });
      });
    }

    if (isOtp) {
      // When OTP, a "Factors" radio option is added
      radioOptions.push({
        key: "2",
        name: "Factors",
        value: 1,
      });

      // When OTP Factors should appear before the Culture option
      radioOptions = radioOptions.reverse();
    }
    setRadioOptions(radioOptions);
  }, [outcomesQuestions]);

  useEffect(() => {
    // Reset the filters if there is no data
    dispatch({
      type: SET_NEW_FILTERS,
      payload: [],
    });
  }, []);

  useEffect(() => {
    let filteredLatestData;

    if (latestData && latestData?.length > 0) {
      if (latestData?.[0]?.[0]) {
        filteredLatestData = filter_data(latestData?.[0], selectedFilters);
      } else {
        filteredLatestData = filter_data(latestData, selectedFilters);
      }
      const filteredLastData = filter_data(lastData, selectedFilters);

      setFilteredLastData(filteredLastData);
      setFilteredLatestData(filteredLatestData);
      setPageFirstLoad(false);
    }
  }, [selectedFilters, latestData, lastData, pageFirstLoad]);

  useEffect(() => {
    if (
      quarterlyData?.length > 0 &&
      outcomesQuestions?.questions?.length > 0 &&
      !isOtp &&
      core_data?.categories?.[0] &&
      anchor &&
      !core_data?.hasNoData
    ) {
      let usedAnchor = anchor;
      const anchorOptions = core_data?.categories[0]?.categories?.find(
        (cat) => cat.id === anchor
      )?.options;

      if (!anchorOptions) {
        usedAnchor = core_data?.categories[0]?.categories?.find(
          (cat) => cat.priority === "primary"
        )?.id;
      }

      const data = outcomesQuestions?.questions?.map((question) => {
        let calcData = getAnchorAveragesByQuestion(
          quarterlyData,
          usedAnchor,
          question?.id,
          core_data?.categories?.[0],
          orgRule
        );

        const scores = processScores(calcData);
        calcData[0]?.anchorData.sort((a, b) => a.average - b.average).reverse();
        const uniqueLocationData = getUniqueLocationData(calcData);

        return {
          id: question?.id,
          data: calcData,
          label: question?.name,
          scores: scores,
          uniqueLocationData: uniqueLocationData,
        };
      });

      setCalculatedOutcomes(data);
    }
  }, [
    quarterlyData,
    outcomesQuestions,
    anchor,
    core_data?.categories?.[0],
    selectedFilters,
  ]);

  const generateOTPScoreCards = (latest, last, structure, anchor) => {
    // Get the calculated card scores.
    const calculated = calculateOtpCardAverages(
      latest,
      last,
      structure,
      anchor
    );

    // Function to get highest/lowest.
    function findHighestAndLowestScores(data) {
      if (
        !data ||
        !Array.isArray(data.anchorData) ||
        data.anchorData.length === 0
      ) {
        return null;
      }

      let highest = data.anchorData[0];
      let lowest = data.anchorData[0];

      for (let i = 1; i < data.anchorData.length; i++) {
        const current = data.anchorData[i];
        if (current.overallScore > highest.overallScore) {
          highest = current;
        }
        if (current.overallScore < lowest.overallScore) {
          lowest = current;
        }
      }

      return {
        highest: getLabel(
          highest.id,
          anchor,
          core_data?.categories[0]?.categories
        ),
        lowest: getLabel(
          lowest.id,
          anchor,
          core_data?.categories[0]?.categories
        ),
      };
    }

    return (
      <CardGroups>
        {calculated?.map((dimension) => {
          const highestAndLowest = findHighestAndLowestScores(dimension);
          const icon = getIcon(null);
          const overallScore = Math.round(dimension?.overallScore * 10);
          const change = Math.round(dimension?.change * 10);
          return (
            <div style={{ width: "50%", paddingRight: 8 }}>
              <ScoreCard
                score={overallScore}
                highest={highestAndLowest?.highest}
                lowest={highestAndLowest?.lowest}
                factor={dimension?.title}
                icon={icon}
                change={dimension?.change && change}
              />
            </div>
          );
        })}
      </CardGroups>
    );
  };

  function computeWeightedFactorAverages(data) {
    const outcomeMap = {};
    let index = 0;
    // Get total responses
    const totalRespondantNum = data.reduce(
      (acc, curr) => acc + curr.totalRespondants,
      0
    );

    const anchorIsPrimary =
      core_data?.categories[0]?.categories?.find((cat) => cat.id === anchor)
        ?.priority === "primary";

    // Traverse each entry in the data array
    data.forEach((entry) => {
      // Traverse each set of outcomes in the entry
      entry.outcomes.forEach((outcomeSet) => {
        outcomeSet.forEach((outcome) => {
          // To find the total respondants number find the outcome.outcomes with the label which matches the anchorOption
          let totalRespondants =
            isOtp && selectedFilters?.length > 0 && anchorIsPrimary
              ? outcome.totalRespondants
              : outcome.outcomes?.find((oc) => {
                  return oc.label === outcome.anchorOption;
                })?.anchorRespondants;

          if (!totalRespondants) {
            totalRespondants = totalRespondantNum;
          }

          if (!outcomeMap[outcome.label]) {
            outcomeMap[outcome.label] = {
              id: outcome.id,
              label: outcome.label,
              totalWeightedSum: 0,
              totalRespondants: totalRespondants,
              anchorOption: outcome.label,
              outcomes: outcome?.outcomes,
              change: 0,
            };
          }

          outcomeMap[outcome.label].totalWeightedSum +=
            outcome.average * totalRespondants;
          outcomeMap[outcome.label].totalRespondants = totalRespondantNum;
        });
      });
    });

    // Convert the outcomeMap to an array with the desired structure
    const result = Object.values(outcomeMap).map((outcome, index) => {
      return {
        id: index,
        label: outcome.label,
        average: Math.round(
          outcome.totalWeightedSum / outcome.totalRespondants
        ),
        change: outcome.change,
        color: colorScheme[index % colorScheme.length],
        anchorOption: outcome.anchorOption,
        outcomes: outcome.outcomes,
      };
    });

    return result;
  }

  useEffect(() => {
    if (
      filteredLatestData &&
      filteredLatestData?.length > 0 &&
      anchor &&
      core_data?.categories &&
      ((calculatedOutcomes && !isOtp) || isOtp)
    ) {
      let usedAnchor = anchor;
      const anchorOptions = core_data?.categories[0]?.categories?.find(
        (cat) => cat.id === anchor
      )?.options;

      if (!anchorOptions) {
        usedAnchor = core_data?.categories[0]?.categories?.find(
          (cat) => cat.priority === "primary"
        )?.id;
      }

      const data = filteredLatestData
        ? calculateScreenData(
            filteredLatestData,
            core_data?.categories,
            usedAnchor,
            outcomesQuestions,
            orgRule,
            selectedFilters,
            isOtp,
            get_survey_structure,
            core_data
          )
        : null;

      const lastData = filteredLastData
        ? calculateScreenData(
            filteredLastData,
            core_data?.categories,
            usedAnchor,
            outcomesQuestions,
            orgRule,
            selectedFilters,
            isOtp,
            get_survey_structure,
            core_data
          )
        : null;

      setScreenData(data);
      setLastScreenData(lastData);

      let chartData = restructureData(
        data?.finalData,
        lastData?.finalData,
        calculatedOutcomes,
        isOtp,
        core_data?.questions
      ).filter((item) => item?.totalRespondants > orgRule);

      let weightedAvgs = isOtp
        ? computeWeightedFactorAverages(chartData)
        : null;

      if (Number(selectedRadio) === 0) {
        chartData.sort((a, b) => b.x - a.x);
      } else {
        if (isOtp) {
          weightedAvgs.sort((a, b) => b.average - a.average);
        } else {
          chartData.sort(
            (a, b) =>
              b?.outcomes?.find((o) => Number(o.id) === Number(selectedRadio))
                ?.average -
              a?.outcomes?.find((o) => Number(o.id) === Number(selectedRadio))
                ?.average
          );
        }
      }

      // Loop through the above data and create a new array containing only the x values
      let xValues;
      if (Number(selectedRadio) === 0) {
        xValues = chartData.map((item) => item.x);
      } else {
        if (isOtp) {
          xValues = weightedAvgs.map((item) => item.average);
        } else {
          // filter out any chartData that does not have an average
          chartData = chartData.filter(
            (item) =>
              item?.outcomes.find(
                (outcome) => Number(outcome.id) === Number(selectedRadio)
              )?.average
          );

          xValues = chartData.map((item) => {
            const outcome = item.outcomes.find(
              (outcome) => Number(outcome.id) === Number(selectedRadio)
            );
            return outcome?.average;
          });
        }
      }
      // Loop through the above data and create a new array containing only the category values
      let categoryValues = chartData.map((item) => item.category);
      if (isOtp && Number(selectedRadio) !== 0) {
        categoryValues = weightedAvgs.map((item) => item.label);
      }

      // Loop through the above data and create a new array containing only the colors values
      let colorValues = chartData.map((item) => item.color);
      if (isOtp && Number(selectedRadio) !== 0) {
        colorValues = weightedAvgs.map((item) => item.color);
      }

      const series = [
        {
          name: categoryValues,
          data: xValues,
        },
      ];

      options.colors = colorValues;
      options.xaxis.categories = categoryValues;
      options.chart.events = {
        click: function (event, chartContext, config) {
          if (isOtp && Number(selectedRadio) !== 0) {
            setSelectedChartItem(weightedAvgs[config?.dataPointIndex]);
          } else {
            setSelectedChartItem(chartData[config?.dataPointIndex]);
          }
        },
      };

      let radioOption = radioOptions.find(
        (option) => Number(option.value) === Number(selectedRadio)
      );

      options.yaxis.title.text = `${radioOption?.name} Score`;

      setBarChartData({
        series: series,
        options: options,
      });

      setCalculatedChartData(chartData);
      setCalculatedFactorChartData(weightedAvgs);
    }
  }, [
    filteredLatestData,
    anchor,
    core_data?.categories,
    outcomesQuestions,
    selectedFilters,
    selectedRadio,
    calculatedOutcomes,
    pageFirstLoad,
  ]);

  useEffect(() => {
    if (
      get_debrief_schedules?.debrief_schedule?.results?.length > 0 &&
      get_survey_questions?.length > 0
    ) {
      const sortOrders = getSortOrders(
        get_debrief_schedules,
        get_survey_questions
      );

      setSortOrderList(sortOrders);
    } else {
      if (organization?.styling) {
        const sortOrder = organization?.services_enabled?.find(
          (item) => item.name === "Audit"
        )?.question_sort_order;
        setSortOrderList([sortOrder]);
      }
    }
  }, [get_debrief_schedules, get_survey_questions, organization]);

  useEffect(() => {
    if (organization?.organization?.styling) {
      const sortOrder =
        organization?.organization?.styling?.survey_sequence?.find(
          (item) => item.value === "outcome_question"
        )?.question?.sort_order;

      if (outcomesQuestionsList?.length > 0 && sortOrder) {
        const questions = outcomesQuestionsList.find(
          (question) => question.sort_order === sortOrder
        );

        if (questions) {
          setOutcomesQuestions(questions);
          setOutcomesData(questions?.questions);
        } else {
          setOutcomesData([]);
        }
      }
    }
  }, [outcomesQuestionsList, organization]);

  useEffect(() => {
    if (
      sortOrderList &&
      get_survey_questions &&
      get_survey_questions?.length > 0
    ) {
      const surveyQuestionsCopy = JSON.parse(
        JSON.stringify(get_survey_questions)
      );

      const mappedList = sortOrderList.map((i) => {
        if (i == 0) {
          return;
        }

        let survey = JSON.parse(
          JSON.stringify(
            surveyQuestionsCopy.filter((sq) => sq.sort_order === i)?.[0]
          )
        );

        survey = restructure_Questions(survey);

        const surveyStructure = {
          title: survey?.questions?.title,
          dimensions: survey?.questions?.dimensions,
          type: survey?.questions?.type,
          type_name: survey?.questions?.type_name,
          comments: survey?.questions?.comments,
          sort_order: survey?.sort_order,
        };

        return surveyStructure;
      });
      setSurveysList(mappedList.filter((f) => f));
    }
  }, [sortOrderList, get_survey_questions]);

  // Get the sort order for the outcome questions.
  useEffect(() => {
    if (organization?.styling) {
      const sortOrder = organization?.styling?.survey_sequence?.find(
        (item) => item.value === "outcome_question"
      )?.question?.sort_order;
      setSortOrder(sortOrder);
    }
  }, [sortOrderList]);

  useEffect(() => {
    if (calculatedChartData) {
      setCultureScore(Math.round(calculateOverallX(calculatedChartData)));
    }
  }, [calculatedChartData]);

  useEffect(() => {
    let last;
    if (cultureScore && filteredLastData) {
      if (filteredLastData) {
        last = get_overall_average(filteredLastData) * 10;
      }

      if (last && cultureScore) {
        setCultureChange((cultureScore - last).toFixed(1));
      } else {
        setCultureChange(0);
      }
    }
  }, [filteredLatestData, filteredLastData, selectedFilters, cultureScore]);

  useEffect(() => {
    if (reportData && core_data?.categories && anchor && selectedSurvey) {
      let usedAnchor = anchor;
      const anchorOptions = core_data?.categories[0]?.categories?.find(
        (cat) => cat.id === anchor
      )?.options;

      if (!anchorOptions) {
        usedAnchor = core_data?.categories[0]?.categories?.find(
          (cat) => cat.priority === "primary"
        )?.id;
      }

      const calculatedData = calculate_table_data(
        reportData,
        filterArray,
        core_data?.categories,
        usedAnchor,
        orgRule,
        selectedSurvey
      );
      setCalculated(calculatedData);
    }
  }, [reportData, core_data?.categories, anchor, selectedSurvey, filterArray]);

  const getScoreCardSection = (data) => {
    return data
      ?.reduce((acc, curr, index) => {
        if (index % 2 === 0) {
          acc.push(calculatedOutcomes.slice(index, index + 2));
        }
        return acc;
      }, [])
      .map((group, index) => {
        return (
          <CardGroups key={index}>
            {group.map((outcome) => {
              const icon = getIcon(outcome?.label);
              const transformedData = transformData(screenData?.finalData);
              const transformedLastData =
                selectedFilters?.length > 0
                  ? transformData(lastScreenData?.finalData)
                  : transformData(lastScreenData?.preFilteredData);


              const highLow = getMinMaxOutcome(
                transformedData,
                outcome?.id,
                outcome
              );

              // Filter out 0 values before calculating the average
              const validTransformedData = transformedData.filter(
                (item) => item?.average !== 0
              );

              const validTransformedLastData = transformedLastData
                ? transformedLastData.filter((item) => item?.average !== 0)
                : null;

              const outcomeOverallAverage = Math.round(
                getAverageOutcome(validTransformedData, outcome?.id) * 10
              );

              const lastOutcomeAverage = validTransformedLastData
                ? Math.round(
                    getAverageOutcome(validTransformedLastData, outcome?.id) *
                      10
                  )
                : 0;


              const change = validTransformedLastData
                ? outcomeOverallAverage - lastOutcomeAverage
                : 0;

              // Log for debugging

              if (isNaN(outcomeOverallAverage)) {
                return null;
              }

              return (
                <div style={{ width: "50%", paddingRight: 8 }}>
                  <ScoreCard
                    score={outcomeOverallAverage}
                    highest={highLow[0]}
                    lowest={highLow[1]}
                    factor={outcome?.label}
                    icon={icon}
                    change={change !== outcomeOverallAverage && change}
                  />
                </div>
              );
            })}
          </CardGroups>
        );
      });
  };

  useEffect(() => {
    if (calculatedChartData && ((calculatedOutcomes && !isOtp) || isOtp)) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
  }, [anchor, calculatedChartData, calculatedOutcomes]);

  if (
    (!calculatedOutcomes && !isOtp) ||
    (!screenData && !calculatedChartData && loading && !core_data?.noData)
  ) {
    return (
      <Dimmer active={loading} inverted>
        <div style={{ display: "none" }}>
          <Anchor anchorLabel=" " />
        </div>
      </Dimmer>
    );
  }

  const hasNoData =
    (((!calculatedOutcomes && !calculatedChartData && !isOtp) ||
      (calculatedChartData && calculatedChartData?.length === 0)) &&
      !loading) ||
    core_data?.noData ||
    (filteredLatestData?.length === 0 && !loading);

  return (
    <Container>
      <div style={{ display: "none" }}>
        <Anchor anchorLabel=" " />
      </div>
      <HeadingSection>
        <CultureLabel>Culture Analytics</CultureLabel>
        <PageTitle>Dashboard</PageTitle>
        {core_data?.permissions?.employee_employee_permission &&
          core_data?.permissions?.employee_employee_permission?.details
            ?.length > 0 && <PermissionsModal core_data={core_data} />}
      </HeadingSection>
      {(hasNoData || (!screenData && calculatedOutcomes?.length === 0)) &&
        !screenData && <Empty />}
      {screenData &&
        ((calculatedOutcomes && calculatedOutcomes?.length > 0 && !isOtp) ||
          isOtp) && (
          <>
            <FilterSection>
              <Filters />
            </FilterSection>
            <DialSection>
              <DialScore
                score={Math.round(cultureScore)}
                change={Math.round(cultureChange)}
              />
              <ScoreCardSection>
                {
                  // split the calculatedOutcomes into 2 groups of 2 (if there are more than 2 outcomes) then add those to an array
                  // then map through the array and create a CardGroup for each group of 2
                  !isOtp
                    ? getScoreCardSection(calculatedOutcomes)
                    : generateOTPScoreCards(
                        filteredLatestData,
                        filteredLastData,
                        core_data?.questions,
                        anchor
                      )
                }
              </ScoreCardSection>
            </DialSection>

            <ChartSection barGraphSelected={useBarGraph}>
              <HeaderSection>
                <LeftToggles>
                  <AnchorGrouping>
                    <ChartHeader>
                      {(useBarGraph && "Bar Chart") || "Culture Map"}
                    </ChartHeader>
                    {core_data?.categories && <Anchor anchorLabel=" " />}
                  </AnchorGrouping>
                  <RadioButtonGroup
                    onChange={setSelectedRadio}
                    options={radioOptions}
                    selected={selectedRadio}
                  />
                </LeftToggles>
                <BarGraphToggle>
                  <BarGraphTextToggleText>Use bar chart</BarGraphTextToggleText>
                  <Checkbox
                    toggle
                    checked={useBarGraph}
                    onChange={() => setUseBarGraph(!useBarGraph)}
                  />
                </BarGraphToggle>
              </HeaderSection>
              {!loading && !hasNoData ? (
                <>
                  {calculatedChartData &&
                    calculatedChartData?.length > 0 &&
                    !useBarGraph && (
                      <CultureMap
                        data={calculatedChartData}
                        factorData={calculatedFactorChartData}
                        isOtp={isOtp}
                        onItemClick={setSelectedChartItem}
                        selectedRadio={selectedRadio}
                        radioOption={
                          radioOptions.find(
                            (option) =>
                              Number(option.value) === Number(selectedRadio)
                          )?.name
                        }
                        bottomLabel={
                          Number(selectedRadio) === 0 ? "Culture" : "Outcome"
                        }
                      />
                    )}
                  {calculatedChartData &&
                    calculatedChartData?.length > 0 &&
                    barChartData &&
                    useBarGraph && (
                      <Chart
                        width={"100%"}
                        options={barChartData?.options}
                        series={barChartData?.series}
                        type="bar"
                        height={610}
                      />
                    )}
                </>
              ) : (
                <Dimmer active={loading} inverted>
                  <Loader content={loadingText} />
                </Dimmer>
              )}
            </ChartSection>
          </>
        )}
      {(hasNoData ||
        (!screenData && calculatedOutcomes?.length === 0 && !isOtp)) && (
        <Empty />
      )}
      {!hasNoData && selectedChartItem && (
        <Modal
          onClose={() => setSelectedChartItem(null)}
          open={selectedChartItem}
          size="tiny"
        >
          <ModalContent>
            <ModalHeading>
              <DepartmentLabelSection>
                <ColoredCircle color={selectedChartItem?.color} />
                <DepartmentLabel>
                  {selectedChartItem?.category || selectedChartItem?.label}
                </DepartmentLabel>
              </DepartmentLabelSection>
              <CloseButton
                onClick={() => {
                  setSelectedChartItem(null);
                }}
              >
                {closeIcon()}
              </CloseButton>
            </ModalHeading>
            <ModalSubSection>
              <NumRespondants>
                {selectedChartItem?.totalRespondants ||
                  selectedChartItem?.outcomes?.[0]?.totalRespondants}{" "}
                respondants
              </NumRespondants>
            </ModalSubSection>
            <ModalCardSection>
              {/* Manually add Culture Card */}
              {((selectedRadio === 0 && isOtp) || !isOtp) && (
                <ModalOutcomeCard>
                  <ModalOutcomeIcon>{ideaIcon()}</ModalOutcomeIcon>
                  <ModalOutcomeLabelScore>
                    <ModalOutcomeLabel>Culture Score</ModalOutcomeLabel>
                    <ScoreRankSection>
                      <ModalOutcomeScore>
                        {selectedChartItem?.x || selectedChartItem?.average}%
                      </ModalOutcomeScore>
                      <RankChangeSection>
                        <ChangeContainer>
                          {!isNaN(selectedChartItem?.y) &&
                            selectedChartItem?.y !== 0 && (
                              <>
                                <Pic
                                  src={selectedChartItem?.y < 0 ? Down : Up}
                                />
                                <ChangeText
                                  changeColor={selectedChartItem?.y > 0}
                                >
                                  {selectedChartItem?.y > 0 ? "+" : ""}
                                  {selectedChartItem?.y.toFixed(2)}%
                                </ChangeText>
                              </>
                            )}

                          {selectedChartItem?.y === 0 ||
                          isNaN(selectedChartItem?.y)
                            ? "--"
                            : ""}
                        </ChangeContainer>
                        <RankLabel>Internal ranking</RankLabel>
                        <ModalOutcomeRank>
                          <ModalBoldedRank>
                            {getPosition(
                              selectedChartItem?.id,
                              calculatedChartData
                            )}
                          </ModalBoldedRank>
                          &nbsp;out of&nbsp;
                          <ModalBoldedRank>
                            {calculatedChartData?.length}
                          </ModalBoldedRank>
                        </ModalOutcomeRank>
                      </RankChangeSection>
                    </ScoreRankSection>
                  </ModalOutcomeLabelScore>
                </ModalOutcomeCard>
              )}
              {selectedChartItem?.outcomes?.map((outcome) => {
                if (isNaN(outcome?.average)) {
                  return;
                }
                return (
                  <ModalOutcomeCard>
                    <ModalOutcomeIcon>
                      {getIcon(outcome?.label)}
                    </ModalOutcomeIcon>
                    <ModalOutcomeLabelScore>
                      <ModalOutcomeLabel>{outcome?.label}</ModalOutcomeLabel>
                      <ScoreRankSection>
                        <ModalOutcomeScore>
                          {outcome?.average}%
                        </ModalOutcomeScore>
                        <RankChangeSection>
                          <ChangeContainer>
                            {outcome?.outcomeChange !== 0 &&
                              !isNaN(outcome?.outcomeChange) && (
                                <>
                                  <Pic
                                    src={outcome?.outcomeChange < 0 ? Down : Up}
                                  />
                                  <ChangeText
                                    changeColor={outcome?.outcomeChange > 0}
                                  >
                                    {outcome?.outcomeChange > 0 ? "+" : ""}
                                    {outcome?.outcomeChange.toFixed(2)}%
                                  </ChangeText>
                                </>
                              )}
                            {(outcome?.outcomeChange === 0 ||
                              isNaN(outcome?.outcomeChange)) &&
                              "--"}
                          </ChangeContainer>
                          <RankLabel>Internal ranking</RankLabel>
                          <ModalOutcomeRank>
                            <ModalBoldedRank>
                              {outcome?.groupRank}
                            </ModalBoldedRank>
                            &nbsp;out of&nbsp;
                            <ModalBoldedRank>
                              {outcome?.totalGroups}
                            </ModalBoldedRank>
                          </ModalOutcomeRank>
                        </RankChangeSection>
                      </ScoreRankSection>
                    </ModalOutcomeLabelScore>
                  </ModalOutcomeCard>
                );
              })}
            </ModalCardSection>
          </ModalContent>
        </Modal>
      )}
    </Container>
  );
};
export default Analytics;

const LeftToggles = styled.div`
  display: flex;
  flex-direction: column;
`;

const AnchorGrouping = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ModalOutcomeCard = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  padding: 10px;
  height: 75px;
  width: 230px;
  margin-bottom: 10px;
  background-color: #f8faff;
`;

const RankChangeSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-top: -18px;
`;

const ChangeContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
`;

const Pic = styled.img`
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  margin-right: 3px;
`;

const ChangeText = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: ${(props) => (props.changeColor ? "#1D9A7D" : "#F15062")};
`;

const ModalOutcomeIcon = styled.div`
  padding: 6px;
  border-radius: 8px;
  background-color: #ebf1fd;
  height: 35px;
  width: 35px;
  margin-right: 10px;
`;

const ModalSubSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const NumRespondants = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #666d79;

  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "ss03" on, "ss04" on, "ss09" on, "ss10" on, "ss11" on, "salt" on, "ss01" on,
    "ss02" on, "liga" off, "kern" off;
`;

const ModalOutcomeLabelScore = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const BarGraphToggle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-right: 20px;
  margin-left: auto;

  height: 50px;
`;

const BarGraphTextToggleText = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #666d79;
  margin-right: 10px;
`;

const HeaderSection = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  width: 100%;
`;

const ModalOutcomeLabel = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #2d70e2;
  margin-bottom: 13px;
`;

const ScoreRankSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ModalOutcomeScore = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #2a3039;
  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "ss03" on, "ss04" on, "ss09" on, "ss10" on, "ss11" on, "salt" on, "ss01" on,
    "ss02" on, "liga" off, "kern" off;
`;

const ModalOutcomeRank = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 125%;
  /* identical to box height, or 15px */
  display: flex;
  align-items: center;
  font-feature-settings: "pnum" on, "lnum" on, "salt" on, "ss01" on, "ss02" on,
    "ss09" on, "ss05" on, "ss07" on, "ss08" on, "kern" off, "liga" off;
  color: #666d79;
`;

const ModalBoldedRank = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
`;

const DepartmentLabelSection = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const ColoredCircle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
  margin-right: 10px;
`;

const DepartmentLabel = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  font-feature-settings: "pnum" on, "lnum" on, "salt" on, "ss01" on, "ss02" on,
    "ss09" on, "ss05" on, "ss07" on, "ss08" on, "kern" off, "liga" off;
  color: #2a3039;
`;

const ModalCardSection = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const ModalHeading = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  font-size: 20px;
  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "ss03" on, "ss04" on, "ss09" on, "ss10" on, "ss11" on, "salt" on, "ss01" on,
    "ss02" on, "liga" off, "kern" off;
  margin-bottom: 20px;
  color: #2a3039;
`;

const CloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-left: auto;
  margin-top: -5px;
`;

const Container = styled.div`
  font-family: "Raleway";
  width: calc(100vw - 300px);
  position: relative;
`;

const PageTitle = styled.h1`
  display: flex;
  align-items: flex-start;
`;

const ScoreCardSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

const HeadingSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const CultureLabel = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  font-feature-settings: "pnum" on, "lnum" on, "salt" on, "ss01" on, "ss02" on,
    "ss09" on, "ss05" on, "ss07" on, "ss08" on, "kern" off, "liga" off;

  /* Primary/500 */
  color: #2d70e2;

  display: flex;
  align-items: flex-start;
  margin-bottom: -15px;
`;

const FilterSection = styled.div``;

const ChartHeader = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  margin-right: 20px;
  font-feature-settings: "pnum" on, "lnum" on, "ss05" on, "ss07" on, "ss08" on,
    "ss03" on, "ss04" on, "ss09" on, "ss10" on, "ss11" on, "salt" on, "ss01" on,
    "ss02" on, "liga" off, "kern" off;
  margin-bottom: 20px;
  color: #2a3039;
`;

const ChartDropdown = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  font-feature-settings: "pnum" on, "lnum" on, "salt" on, "ss01" on, "ss02" on,
    "ss09" on, "ss05" on, "ss07" on, "ss08" on, "kern" off, "liga" off;
  color: #666d79;
`;

const DialSection = styled.div`
  display: flex;
  margin-top: 30px;
`;

const ChartSection = styled.div`
  margin-top: 30px;
  margin-bottom: ${(props) => (props.barGraphSelected ? "0px" : "50px")};
`;

// Stack the cards in rows of 2 and 2
const CardGroups = styled.div`
  display: flex;
  flex-direction: row;
  // resize the div based on window size
  width: 100%;
  justify-content: flex-start;
`;

const RankLabel = styled.div`
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #666d79;
  margin-bottom: 5px;
`;
import Settings from "assets/images/nav/settings.svg";
import Home from "assets/images/nav/home.svg";
import Management from "assets/images/nav/management.svg";
import L360 from "assets/images/nav/360.svg";
import Report from "assets/images/nav/report.svg";
import PDFIcon from "assets/images/nav/pdf-regular-white.svg";

import Culture from "assets/images/nav/culture.svg";
import Mood from "assets/images/nav/mood.svg";
import Personality from "assets/images/nav/personality.svg";

import Leadership from "assets/images/nav/leadership.svg";
import Admin from "assets/images/nav/admin.svg";
import MyMood from "assets/images/group-12.svg";
import Survey from "assets/images/nav/survey.svg";

import Data from "assets/images/nav/data.svg";

import Calendar from "assets/images/nav/calendar.png";
import Action from "assets/images/nav/action.png";

import {
  TeamIcon,
  actionIcon,
  cultureIcon,
  calendarIcon,
  dataScienceIcon,
  groupIcon,
  recommendationIcon,
  outcomesIcon,
  aiIcon,
  dashboardIcon,
  reportIcon,
  analyticsIcon,
  sharingIcon,
  layers
} from "./Icons";

// before you add things here check if the route is valid
// checking this data is done in sidebar.js
// use the name property to loop through
export const CommonNav = [
  {
    icon: "address book",
    link: "/core/management",
    title: "Team Management",
    image: Management,
    role: 7,
    paid: true,
    team: false,
    name: null,
    group: "Common",
  },
];
export const OtpNav = [];

export const NavData = [
  {
    header: "Culture",
    role: 9,
    paid: false,
    team: false,
    name: "Audit",
    group: "Audit",
  },
  {
    icon: "dollar",
    link: "/leader/audit/simple",
    title: "Culture Analytics",
    image: Report,
    svg: analyticsIcon,
    prototype: true,
    role: 5,
    paid: true,
    team: false,
    require_access_data: true,
    name: "Audit",
    group: "Audit",
    innerlogic_only: false,
    no_subfactor: true,
    subNav: [
      {
        link: "/leader/audit/simple/dashboard",
        title: "Analytics Hub",
      },
      {
        link: "/leader/audit/simple/insights",
        title: "Data Stories",
        relatedLinks: ["/leader/audit/simple/insight"],
      },
      {
        link: "/leader/audit/simple/heatmap",
        title: "Heatmaps",
      },
      {
        link: "/leader/audit/simple/benchmarks",
        title: "Benchmarks",
      },
      {
        link: "/leader/audit/simple/change",
        title: "Changes",
      },
      {
        link: "/leader/audit/simple/feedback",
        title: "Feedback",
        require_feedback_access: true,
      },
      {
        link: "/leader/audit/simple/relationships",
        title: "ROI insights",
        beta: true,
      },
      // {
      //   link: "/leader/audit/simple/kindred",
      //   title: "Kindred",
      //   demo: true,
      // },
    ],
  },
  // {
  //   icon: "dollar",
  //   link: "/leader/prototype/leaderaccountability",
  //   title: "Accountability",
  //   image: Report,
  //   svg: cultureIcon,
  //   prototype: true,
  //   role: 5,
  //   paid: true,
  //   team: false,
  //   require_access_data: true,
  //   name: "Audit",
  //   group: "Audit",
  //   innerlogic_only:true,
  //   no_subfactor:true,
  // },
  {
    icon: "dollar",
    link: "/leader/audit/reports",
    title: "Reports",
    image: Report,
    svg: reportIcon,
    prototype: true,
    role: 5,
    paid: true,
    team: false,
    require_access_data: true,
    name: "Audit",
    group: "Audit",
    innerlogic_only: true,
    no_subfactor: true,
    subNav: [
      {
        link: "/leader/audit/reports",
        title: "PDF Reports",
      },
      {
        link: "/leader/audit/reports/recV4",
        title: "Recommendations",
        required_recommendation: true,
      },
    ],
  },
  {
    icon: "address book",
    link: "/leader/audit/AI/chat",
    title: "Culture Copilot",
    image: Report,
    svg: aiIcon,
    role: 9,
    paid: true,
    team: false,
    require_access_data: true,
    require_feedback_access: false,
    name: "AI",
    group: "Audit",
    subNav: [
      {
        link: "/leader/audit/AI/chat",
        title: "Chat",
      },
      {
        link: "/leader/audit/AI/history",
        title: "History",
      },
    ],
  },
  {
    icon: "dollar",
    link: "/leader/prototype/groupdata",
    title: "Business Intelligence",
    image: Report,
    svg: layers,
    demo: true,
    role: 5,
    paid: true,
    team: false,
    require_access_data: true,
    name: "Audit",
    group: "Audit",
    innerlogic_only: true,
    no_subfactor: true,
    subNav: [
      {
        link: "/leader/prototype/groupdata",
        title: "Manage",
      },
      {
        link: "/leader/prototype/groupdata/analysisdashboard",
        title: "Business outcomes",
        required_recommendation: true,
      },
    ],
  },
  {
    icon: "address book",
    link: "/leader/prototype/modelhistory",
    title: "Predictive Models",
    image: Report,
    svg: recommendationIcon,
    role: 9,
    paid: true,
    team: false,
    require_access_data: true,
    require_feedback_access: false,
    name: "AI",
    group: "Audit",
    demo: true,
  },
  // {
  //   icon: "dollar",
  //   link: "/leader/audit/manage",
  //   title: "Data Sharing",
  //   image: Report,
  //   svg: sharingIcon,
  //   prototype: true,
  //   role: 5,
  //   paid: true,
  //   team: false,
  //   demo: true,
  //   require_access_data: true,
  //   name: "Audit",
  //   group: "Audit",
  //   innerlogic_only: true,
  //   no_subfactor: true,
  //   subNav: [
  //     {
  //       link: "/leader/audit/manage",
  //       title: "Leader Accountability",
  //     },
  //     {
  //       link: "/leader/audit/manage/tasktemplates",
  //       title: "Custom surveys",
  //     },
  //     // {
  //     //   link: "/leader/audit/manage/tasks",
  //     //   title: "My assigned surveys",
  //     // },
  //     {
  //       link: "/reports/Action",
  //       title: "Action planning",
  //     },
  //   ],
  // },
  {
    icon: "dollar",
    link: "/leader/audit/manage/tasktemplates",
    title: "Custom Survey",
    image: Report,
    svg: sharingIcon,
    prototype: true,
    role: 5,
    paid: true,
    team: false,
    demo: false,
    require_access_data: true,
    name: "Audit",
    group: "Audit",
    innerlogic_only: true,
    no_subfactor: true,
    subNav: [
      {
        link: "/leader/audit/manage/tasktemplates",
        title: "Create Survey",
        relatedLinks: ["/leader/audit/manage/tasks"],
      },
      // {
      //   link: "/leader/audit/manage/tasks",
      //   title: "My custom surveys",
      // },
    ],
  },
  {
    // icon: "home",
    link: "/leader/audit/calendar",
    title: "Schedule",
    image: Calendar,
    svg: calendarIcon,
    role: 9,
    paid: false,
    team: false,
    name: "Audit",
    group: "Audit",
    require_calendar: true,
    subNav: [
      {
        link: "/leader/audit/calendar/active",
        title: "Active surveys",
      },
      {
        link: "/leader/audit/calendar/history",
        title: "History",
      },
    ],
  },
  // {
  //   icon: "dollar",
  //   link: "/leader/audit/outcomes",
  //   title: "Outcomes",
  //   svg: outcomesIcon,
  //   role: 9,
  //   paid: true,
  //   team: false,
  //   require_access_data: true,
  //   name: "Audit",
  //   group: "Audit",
  //   innerlogic_only:true,
  //   no_subfactor:true,
  // },
  {
    icon: "address book",
    link: "/leader/audit/pdf",
    title: "PDF Report",
    image: PDFIcon,
    svg: reportIcon,
    role: 9,
    paid: true,
    team: false,
    require_access_data: true,
    name: "Audit",
    group: "Audit",
    employee_permission: true,
    not_for_innerlogic: true,
  },
  {
    header: "Settings",
    role: 6,
    paid: true,
    team: false,
    name: null,
    group: "Common",
  },
  {
    icon: "address book",
    link: "/leader/members/list",
    title: "Team Management",
    fr: "Gestion de l'équipe",
    image: Management,
    svg: TeamIcon,
    border: true,
    role: 6,
    paid: true,
    team: false,
    name: null,
    require_add_users: true,
    group: "Common",
  },
];

export default NavData;

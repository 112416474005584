import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Modal,
  Input,
  Button as SemanticButton,
  Icon,
  Dropdown,
} from "semantic-ui-react";
import promptsData from "./prompts.json";
import starterQuestions from "./starterQuestions.json";
import { useSelector, useDispatch, shallowEqual } from "react-redux";

const PromptLibrary = ({ onSelect, setCategoryMeta }) => {
  const [open, setOpen] = useState(false);
  const [prompts, setPrompts] = useState([]);
  const [filteredPrompts, setFilteredPrompts] = useState([]);
  const [starterQues, setStarterQues] = useState([]);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [finalSelectedPrompt, setFinalSelectedPrompt] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categoryDropdown, setCategoryDropdown] = useState([]);

  const { core_data } = useSelector(
    (state) => ({
      core_data: state.audit?.core_data,
    }),
    shallowEqual
  );

  useEffect(() => {
    setPrompts(promptsData);
    setFilteredPrompts(promptsData);
    setStarterQues(starterQuestions);
    setSelectedCategory(null);
    setCategoryMeta(null);
  }, []);

  useEffect(() => {
    if (core_data) {
      setCategoryDropdown(createCategoryDropdown(core_data));
    }
  }, [core_data]);

  const createCategoryDropdown = (core) => {
    const categories =
      core?.categories?.length > 0 && core?.categories?.[0]?.categories;
    // Loop through categories and create an array with the object structure:
    //  { id: category.id, name: category.name, value: category.name }
    const categoryDropdown = categories?.map((category) => {
      return {
        id: category.id,
        text: category.name,
        value: category.id,
      };
    });
    return categoryDropdown;
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    setFilteredPrompts(
      prompts.filter(
        (prompt) =>
          prompt.title.toLowerCase().includes(term) ||
          prompt.description.toLowerCase().includes(term) ||
          prompt.tags.some((tag) => tag.toLowerCase().includes(term))
      )
    );
  };

  const handlePromptSelect = (prompt) => {
    setSelectedPrompt(prompt);
    setSelectedCategory(null); // Reset category selection when a new prompt is selected
    setCategoryMeta(null);
  };

  const confirmPromptSelect = () => {
    onSelect(selectedPrompt);
    setFinalSelectedPrompt(selectedPrompt);
    setOpen(false);
  };

  const handleCategoryChange = (e, { value }) => {
    setSelectedCategory(value);
    setCategoryMeta(value);
  };

  const handleRemovePrompt = () => {
    onSelect(null);
    setFinalSelectedPrompt(null);
  };

  return (
    <Container selected={finalSelectedPrompt}>
      <SemanticButton primary onClick={() => setOpen(true)}>
        Open Prompt Library
      </SemanticButton>

      {finalSelectedPrompt && (
        <SelectedPromptCard>
          <PromptInfo>
            <PromptTitle>{finalSelectedPrompt.title}</PromptTitle>
            <PromptDescription>
              {finalSelectedPrompt.description}
            </PromptDescription>
          </PromptInfo>
          <RemoveButton onClick={handleRemovePrompt}>
            <Icon name="close" />
          </RemoveButton>
        </SelectedPromptCard>
      )}

      <Modal open={open} onClose={() => setOpen(false)} size="large">
        <Modal.Header>Prompt Library</Modal.Header>
        <Modal.Content>
          <SearchBar
            placeholder="Search prompts..."
            value={searchTerm}
            onChange={handleSearch}
            fluid
          />
          <ContentWrapper>
            <SelectionPane>
              {/* Prompts with Filters */}
              <SectionTitle>Comprehensive analysis</SectionTitle>
              {filteredPrompts.map((prompt) => (
                <PromptItem
                  key={prompt.id}
                  onClick={() => handlePromptSelect(prompt)}
                  active={selectedPrompt && selectedPrompt.id === prompt.id}
                >
                  {prompt.title}
                </PromptItem>
              ))}

              {/* Starter Questions */}
              <SectionTitle>Starter Questions</SectionTitle>
              {starterQues.map((ques, index) => (
                <PromptItem
                  key={index}
                  onClick={() => handlePromptSelect(ques)}
                  active={selectedPrompt && selectedPrompt.title === ques.title}
                >
                  {ques.title}
                </PromptItem>
              ))}
            </SelectionPane>

            <DetailsPane>
              {selectedPrompt ? (
                <>
                  <PromptTitle>{selectedPrompt.title}</PromptTitle>
                  <PromptDescription>
                    {selectedPrompt.description || selectedPrompt.ques}
                  </PromptDescription>
                  {selectedPrompt.tags && (
                    <Tags>
                      {selectedPrompt.tags.map((tag, index) => (
                        <Tag key={index}>{tag}</Tag>
                      ))}
                    </Tags>
                  )}
                  {/* Display category dropdown if the prompt has a category flag */}
                  {selectedPrompt.category && (
                    <>
                      <CategoryLabel>Select a category</CategoryLabel>
                      <Dropdown
                        placeholder="Select Category"
                        fluid
                        selection
                        options={categoryDropdown}
                        onChange={handleCategoryChange}
                        value={selectedCategory}
                      />
                    </>
                  )}
                </>
              ) : (
                <Placeholder>
                  Select a prompt or question to see details
                </Placeholder>
              )}
            </DetailsPane>
          </ContentWrapper>
        </Modal.Content>
        <Modal.Actions>
          <SemanticButton onClick={() => setOpen(false)}>Cancel</SemanticButton>
          <SemanticButton
            primary
            disabled={
              !selectedPrompt || (selectedPrompt.category && !selectedCategory)
            }
            onClick={confirmPromptSelect}
          >
            Ask Prompt
          </SemanticButton>
        </Modal.Actions>
      </Modal>
    </Container>
  );
};

export default PromptLibrary;

// Styled Components
const Container = styled.div`
  font-family: "Raleway", sans-serif;
  max-width: 800px;
  height: ${({ selected }) => (selected ? "150px" : "50px")};
`;

const SearchBar = styled(Input)`
  margin-bottom: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  max-height: 90%;
`;

const SelectionPane = styled.div`
  width: 30%;
  border-right: 1px solid #ddd;
  overflow-y: auto;
`;

const SectionTitle = styled.div`
  margin-top: 15px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #333;
  padding-left: 10px;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
`;

const PromptItem = styled.div`
  padding: 15px;
  cursor: pointer;
  background-color: ${({ active }) => (active ? "#f0f8ff" : "white")};
  font-family: "Raleway", sans-serif;
  &:hover {
    background-color: #f0f8ff;
  }
`;

const DetailsPane = styled.div`
  width: 70%;
  padding: 20px;
  overflow-y: auto;
`;

const PromptTitle = styled.div`
  margin-top: 0;
  font-size: 16px;
  margin-bottom: 10px;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
`;

const PromptDescription = styled.div`
  font-size: 14px;
  line-height: 1.6;
  font-family: "Raleway", sans-serif;
`;

const Tags = styled.div`
  margin-top: 20px;
`;

const Tag = styled.span`
  display: inline-block;
  background-color: #e0e1e2;
  color: #555;
  padding: 5px 10px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 4px;
  font-size: 12px;
`;

const Placeholder = styled.div`
  color: #999;
  font-size: 18px;
  text-align: center;
  margin-top: 50px;
`;

const SelectedPromptCard = styled.div`
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-left: 5px solid #2185d0;
  background-color: #fafafa;
  display: flex;
  align-items: flex-start;
  border-radius: 8px;
  position: relative;
`;

const PromptInfo = styled.div`
  flex: 1;
`;

const RemoveButton = styled.div`
  cursor: pointer;
  color: #999;
  &:hover {
    color: #333;
  }
`;

const CategoryLabel = styled.div`
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
`;

const CategoryDisplay = styled.div`
  margin-top: 10px;
  font-weight: bold;
  color: #2185d0;
`;